<template>
  <div>
    <banner :banners="banners"/>
    <div class="layout">
      <div class="date-filter">
        <div class="themes">
          <div class="item" v-for="item of activityClassification"
               :class="[item.key,{active:item.id===params.currentClassification}]"
               :key="item.key"
               @click="selectClassification(item.id)">
            <img class="ico" :src="item.icon"/>
            <div class="tit">{{item.label}}</div>
          </div>
        </div>
        <month class="dates" :no-default-value="true" @change="changeMonth" ref="month"/>
      </div>
      <div class="search-bar">
        <a class="btn-submit" @click="getList()">{{$t('search')}}</a>
        <div class="ipt-wrap">
          <input type="text" :placeholder="$t('EnterWhereYouWantToGo')" v-model.trim="params.keyword" @keyup.enter="getList()"/>
        </div>
      </div>
      <div class="main cl">
        <div class="filters">
          <!--已選擇-->
          <div class="item" v-if="slectedList.length">
            <div class="hd cl">
              <span class="tit">{{$t('Selected')}}</span>
              <a class="link" @click="clearOptions">{{$t('ClearOption')}}</a>
            </div>
            <div class="bd slected-list">
              <div class="cell" v-for="item of slectedList" :key="item.id">
                <div class="label c1">
                  <span class="name">{{item.name}}</span>
                  <a class="btn-del" @click="deleteSelectedItem(item)">X</a>
                </div>
              </div>
            </div>
          </div>
          <!--目的地-->
          <div class="item">
            <div class="hd cl">
              <span class="tit">{{$t('Destination')}}</span>
              <a class="link" @click="allSelect('cities','businessId',cityRecommends)">{{$t('AllDestinations')}}</a>
            </div>
            <div class="bd">
              <checkbox class="cell"
                        v-for="item of cityRecommends"
                        :value="params.cities.includes(item.businessId)"
                        :key="item.id"
                        @click.native="selectFilterData('cities',item.businessId)">
                <div class="label">{{item.name}}</div>
              </checkbox>
            </div>
          </div>
          <!--活動分類-->
          <div class="item">
            <div class="hd cl">
              <span class="tit">{{$t('ActivityClassification')}}</span>
              <a class="link" @click="allSelect('classifications','code',productClassifications)">
                {{$t('AllActivityClassification')}}
              </a>
            </div>
            <div class="bd">
              <checkbox class="cell" v-for="item of productClassifications"
                        :value="params.classifications.includes(item.code)"
                        :key="item.id"
                        @click.native="selectFilterData('classifications',item.code);params.currentClassification=''">
                <div class="label">{{item.name}}</div>
              </checkbox>
            </div>
          </div>
          <!--價錢-->
          <div class="item">
            <div class="hd cl">
              <span class="tit">{{$t('Cost')}}({{currency}})</span>
            </div>
            <div class="bd" style="padding:0 10px;">
              <div class="relative">
                <el-slider :show-tooltip="false" range :step="10" :max="80000" v-model="price" @change="changePrice"/>
                <div class="cl">
                  <div class="fl fc9">0</div>
                  <div class="fr fc9">80000</div>
                </div>
                <div class="slider-tooltip" :style="{left:price[0]/800+'%'}">{{price[0]}}</div>
                <div class="slider-tooltip" :style="{left:price[1]/800+'%'}">{{price[1]}}</div>
              </div>
            </div>
          </div>
          <!--天數-->
          <div class="item">
            <div class="hd cl">
              <span class="tit">{{$t('Days')}}</span>
              <a class="link" @click="allSelect('days','id',times)">{{$t('ViewAllDays')}}</a>
            </div>
            <div class="bd">
              <checkbox class="cell" v-for="item of times"
                        :value="params.days.includes(item.id)"
                        :key="item.id"
                        @click.native="selectFilterData('days',item.id)">
                <div class="label">{{item.name}}</div>
              </checkbox>
            </div>
          </div>
          <!--旅行社-->
          <div class="item">
            <div class="hd cl">
              <span class="tit">{{$t('TravelAgency')}}</span>
              <a class="link" @click="allSelect('businesses','userId',businesses)">{{$t('AllTravelAgency')}}</a>
            </div>
            <div class="bd">
              <checkbox class="cell" v-for="item of businesses"
                        :value="params.businesses.includes(item.userId)"
                        :key="item.businessId"
                        @click.native="selectFilterData('businesses',item.userId)">
                <div class="label">{{item.businessName}}</div>
              </checkbox>
            </div>
          </div>
        </div>
        <div class="gap-line"></div>
          <div class="hd">
            <div class="searchbar">
              <el-date-picker class="date-picker"
                              v-model="dates"
                              size="small"
                              type="daterange"
                              range-separator="-"
                              :picker-options="pickerOptions"
                              :start-placeholder="$t('StartDate')"
                              :end-placeholder="$t('EndDate')"
                              value-format="yyyy-MM-dd"
                              @change="changeDates">
              </el-date-picker>
              <el-select class="vam" clearable size="small" :placeholder="$t('Sort')" :value="params.orderType"
                         @change="changeSort">
                <el-option v-for="item in sortOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="checkbox-group">
              <checkbox class="cell" :value="listType.length===params.moreCondition.length">
                <div class="label" @click="allSelect('moreCondition','id',listType)">{{$t('CheckAll')}}</div>
              </checkbox>
              <checkbox class="cell" v-for="item of listType"
                        :value="params.moreCondition.includes(item.id)"
                        :key="item.id"
                        @click.native="selectFilterData('moreCondition',item.id)">
                <div class="label">{{item.name}}</div>
              </checkbox>
            </div>
          </div>
        <div class="main-list">
          <div class="cl tourspage">
            <theme-travel-item v-for="(item,index) of list"
                               :datas="item"
                               :index="index"
                               :list.sync="list"
                               :key="item.productId"/>
          </div>
        </div>
          <el-pagination class="pages"
                         background
                         layout="prev, pager, next"
                         :page-count="totalPages"
                         @current-change="changePage"
                         hide-on-single-page>
          </el-pagination>
          <empty v-if="totalPages===0" :title="$t('NoData')"/>
      </div>
    </div>
  </div>
</template>
<script>
  import Banner from './components/Banner';
  import Checkbox from '../../components/Checkbox';
  import Month from '../../components/Month';
  import ThemeTravelItem from '../../components/ThemeTravelItem';
  import Empty from '../../components/Empty';
  import tours from '../../mixins/tours';
  import i18n from '../../i18n';
  import {scrollToY} from '../../utils';
  import {getProductList} from '../../api/product';

  export default {
    name: 'ThemeTravel',
    mixins: [tours],
    data() {
      return {
        banners: [],
        activityClassification: [
          {
            id: 'qzt',
            label: i18n.t('parentChild'),
            icon: require('../../assets/images/themetravel/parent-child.png'),
            key: 'parent-child'
          },
          {
            id: 'yxt',
            label: i18n.t('studyTour'),
            icon: require('../../assets/images/themetravel/study-tour.png'),
            key: 'study-tour'
          },
          {
            id: 'cxt',
            label: i18n.t('Meditation'),
            icon: require('../../assets/images/themetravel/pilgrimage.png'),
            key: 'meditation'
          },
          {
            id: 'syt',
            label: i18n.t('photography'),
            icon: require('../../assets/images/themetravel/photography.png'),
            key: 'photography'
          }
        ],
        list: [],
        totalPages: 1
      };
    },
    components: {Empty, Banner, Checkbox, Month, ThemeTravelItem},
    created() {
      this.getList();
    },
    methods: {
      // 获取产品列表
      getList(page = 1) {
        getProductList({page, perPage: 15, ...this.getParams()}).then(res => {
          const {value} = res;
          const list = value.list || [];
          this.list = list;
          this.totalPages = value.totalPages;
        });
      },
      changePage(page) {
        scrollToY(document.getElementById('app'), 730);
        this.getList(page);
      }
    }
  };
</script>
<style scoped lang="less">
  .layout{
    padding: 0 5vw 0;
  }
  .tourspage{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .searchbar{
    display: flex;
    justify-content: center;
  }
  .date-filter{
    margin-top:30px;overflow:hidden;
    .dates{height:230px;margin-right:380px;}
  }
  .themes{
    float:right;width:370px;
    .item{
      position:relative;float:left;width:180px;height:110px;margin:0 10px 10px 0;padding-top:18px;overflow:hidden;text-align:center;cursor:pointer;border-radius:4px;
      &:nth-child(even){margin-right:0;}
      .tit{margin-top:10px;line-height:1;font-size:24px;}
      .ico,
      .tit{opacity:0.3;transition:all 0.3s;}
      &.active{
        &:after{position:absolute;top:0;right:0;bottom:0;left:0;border:3px solid transparent;content:'';}
      }
      &.active,
      &:hover{
        .ico,
        .tit{opacity:1;}
      }
    }
    .parent-child{
      background-color:#ffdbd7;
      &.active{
        &:after{border-color:#ff6f61;}
      }
      .tit{color:#ff6f61;}
    }
    .study-tour{
      background-color:#c9f7de;
      &.active{
        &:after{border-color:#00b680;}
      }
      .tit{color:#00b680;}
    }
    .meditation{
      background-color:#fff4d7;
      &.active{
        &:after{border-color:#f5a623;}
      }
      .tit{color:#f5a623;}
    }
    .photography{
      background-color:#d7e8ff;
      &.active{
        &:after{border-color:#4a90e2;}
      }
      .tit{color:#4a90e2;}
    }
  }
  .main{
    position:relative;
    .gap-line{position:absolute;top:0;left:240px;width:1px;height:100%;background-color:#ddd;}
  }
  .main-list{

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > .hd{padding-bottom:20px;}
  }
  .filters{
    float:left;width:240px;padding-right:20px;
    .item{
      padding:30px 0;border-bottom:1px solid #ddd;
      &:first-child{padding-top:0;}
    }
    .hd{
      .tit{float:left;font-size:16px;}
      .link{float:right;color:#1890ff;}
    }
    .bd{margin-top:20px;}
  }
  .cell{
    margin-top:18px;overflow:hidden;
    .label{
      padding-left:24px;line-height:16px;color:#666;
      &.c1{padding-left:8px;color:#999;}
    }
    .btn-del{
      padding-left:10px;vertical-align:middle;color:#999;
      &:hover{color:#ff6f61;}
    }
  }
  .slider-tooltip{position:absolute;top:-10px;min-width:36px;text-align:center;color:#ff6f61;transform:translateX(-50%);}
  .date-picker{width:235px;margin-right:8px;vertical-align:middle;}
  .checkbox-group{
    display:flex;flex-wrap:wrap;
    justify-content: center;
    margin-bottom: 10px;
    > div{margin-right:30px;}
  }
  .search-bar{
    height:60px;margin:20px 0 30px;overflow:hidden;background-color:#fff;border-radius:4px;
    .ipt-wrap{
      margin-right:120px;height:100%;
      input{width:100%;height:100%;padding:0 20px;color:#321908;font-size:16px;border:1px solid #d9d9d9;background-color:#fff;border-radius:4px;}
    }
    .btn-submit{float:right;width:120px;line-height:60px;text-align:center;color:#fff;font-size:16px;background-color:#ff6f61;}
  }
  .slected-list{
    .name{display:inline-block;max-width:80%;overflow:hidden;vertical-align:middle;white-space:nowrap;text-overflow:ellipsis;}
  }

  @media only screen and (max-width: 930px) {
  .date-filter {
    display: none;
  }
}
  @media only screen and (max-width: 930px) {
  .filters {
    display: none;
  }
}
  @media only screen and (max-width: 987px) {
  .filters {
    height: 2095px;
  }
}
  @media only screen and (max-width: 930px) {
  .gap-line{
    display: none;
  }
}
</style>
